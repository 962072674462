import React from "react";

class Contact extends React.Component {
  state = {
    name: "",
    email: "",
    message: ""
  };

  submitHandler = e => {
    e.preventDefault();

    if (
      this.state.name === "" ||
      this.state.email === "" ||
      this.state.message === ""
    ) {
      alert("Please fill all fields. Thanks!");
    } else {
      const templateId = "template_ZNqenMXo";

      this.sendFeedback(templateId, {
        message_html: this.state.message,
        from_name: this.state.name,
        from_email: this.state.email
      });
    }
  };

  changeHandler = e => {
    this.setState({ [e.target.name]: e.target.value });
    // console.log(e.target.value);
  };

  sendFeedback(templateId, variables) {
    window.emailjs
      .send("gmail", templateId, variables)
      .then(this.setState({ name: "", email: "", message: "" }))
      .then(alert("Email sent! Thanks!"));
  }

  render = () => {
    return (
      <div className="containerContent">
        <div className="hed2">Say hello!</div><br />
        <div>
          <form onSubmit={this.submitHandler}>
            <span>name</span>
            <br />
            <input
              className="input"
              type="text"
              //   placeholder="name"
              value={this.state.name}
              name="name"
              onChange={this.changeHandler}
            />
            <br />
            <br />
            <span>email</span>
            <br />
            <input
              className="input"
              type="email"
              //   placeholder="email"
              value={this.state.email}
              name="email"
              onChange={this.changeHandler}
            />
            <br />
            <br />
            <span>message</span>
            <br />
            <textarea
              className="input"
              type="textarea"
              //   placeholder="message"
              value={this.state.message}
              name="message"
              onChange={this.changeHandler}
            />
            <br />
            <br />
            <input type="submit" value="submit" />
          </form>
        </div>
      </div>
    );
  };
}

export default Contact;
