import React from "react"

const Education = () => {
    return (
        <div className="containerContent">
            <div className="hed2">Education</div><br />
            <div>
                <ul><li>
                    <div className="hed3">University of Saskatchewan</div><br />
                    <span> Bachelor of Science, 2007</span><br />
                    <span> Major - Computer Science</span><br /><br />
                </li><br />
                    <li>
                        <div className="hed3">Flatiron School</div><br />
                        <span> June 2019 - October 2019</span><br />
                        <span> Software Engineering / Full-Stack Web Development</span><br /><br />
                    </li>
                </ul>
            </div>
        </div>


    )
}

export default Education