import React from "react"

const Portfolio = () => {
    return (
        <div className="containerContent">
            <div className="hed2">Portfolio</div><br />
            {/* <div className="portfolioContent">
                <div className="hed3">You Do You</div><br />
                <span className="projectDesc">
                    A dating app for the pathologically narcissistic
                </span><br />
                <br />
                <iframe title="you do you promo" width="400" height="225" src="https://www.youtube.com/embed/Grcz-s3Nu80" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <iframe title="you do you demo" width="400" height="225" src="https://www.youtube.com/embed/HILN9rc2EOg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe><br /><br />
                <span className="projectText">
                    <a href="https://github.com/dybushnell/you-do-you-frontend">Github frontend repository</a>
                </span><br />
                <span className="projectText">
                    <a href="https://github.com/dybushnell/you-do-you-backend">Github backend repository</a>
                </span><br />
            </div><br /> */}



            <div className="portfolioContent">
                <div className="hed3">CancelComedians.com</div><br />
                <span className="projectDesc">
                    The latest version of Zach Broussard's annual list of the country's top 1000 comedians. <a href="https://www.vulture.com/2019/12/top-1-000-comedians-of-2019-zach-broussard.html">Read the article on Vulture.com!</a>
                </span><br />
                <br />
                <iframe title="CancelComedians demo" width="400" height="225" src="https://www.youtube.com/embed/H_KTFB3QDrE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <span className="projectDesc">
                </span><br /><br />
                <span className="projectText">
                    <a href="https://cancelcomedians.com">Final deployment (locked; not interactive)</a>
                </span><br />
                <span className="projectText">
                    <a href="https://cancelcomedians.herokuapp.com">Demo deployment (interactive)</a>
                </span><br />
                <span className="projectText">
                    <a href="https://github.com/dybushnell/top1000-frontend-demo">Github frontend demo repository</a>
                </span><br />
                <span className="projectText">
                    <a href="https://github.com/dybushnell/top1000-backend-postgres-demo">Github backend demo repository</a>
                </span><br />
            </div><br />

            <div className="portfolioContent">
                <div className="hed3">You Do You</div><br />
                <span className="projectDesc">
                    A dating app for the pathologically narcissistic
                </span><br />
                <br />
                <iframe title="you do you promo" width="400" height="225" src="https://www.youtube.com/embed/Grcz-s3Nu80" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                <iframe title="you do you demo" width="400" height="225" src="https://www.youtube.com/embed/HILN9rc2EOg" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe><br /><br />
                <span className="projectText">
                    <a href="https://github.com/dybushnell/you-do-you-frontend">Github frontend repository</a>
                </span><br />
                <span className="projectText">
                    <a href="https://github.com/dybushnell/you-do-you-backend">Github backend repository</a>
                </span><br />
            </div><br />
            <div className="portfolioContent">
                <div className="hed3">Movie War</div><br />
                <span className="projectDesc">
                    The classic card game War, but using IMDB movie ratings
                </span><br />
                <br />
                <iframe title="movie war demo" width="400" height="225" src="https://www.youtube.com/embed/RjXURJyHhIs" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe><br /><br />
                <span className="projectText">
                    <a href="https://moviewar.herokuapp.com">Heroku deployment</a></span><br />
                <span className="projectText">
                    <a href="https://github.com/DirkHardware/movie-war">Github frontend repository</a></span>
            </div><br />
            <div className="portfolioContent">
                <div className="hed3">Andy Warhol's Choose Your Own Adventure</div><br />
                <span className="projectDesc">
                    Based on the classic series of kids' adventure books, you are trapped in Andy Warhol's Factory and must find your way out.
                </span><br /><br />
                {/* <br />
                <img src="http://www.dybushnell.com/images/inflation_db.png"
                    alt="" />  <br /><br />
                <span className="projectText">
                    <a href="https://rubygems.org/gems/inflation_db">RubyGems.org download page</a>
                </span><br />*/}
                <span className="projectText">
                    <a href="https://github.com/dybushnell/warhol_cyoa">Github repository</a>
                </span><br />
            </div> <br /> <br />
            <div className="portfolioContent">
                <div className="hed3">Inflation_DB</div><br />
                <span className="projectDesc">
                    A Ruby gem which calculates inflation for any year between 1635 and 2019. The only inflation-calculating gem that works with pre-1800 and post-2014 data. Written with the primary intent of learning about the Ruby gem publishing process.
                </span><br />
                <br />
                <img src="http://www.dybushnell.com/images/inflation_db.png"
                    alt="" />  <br /><br />
                <span className="projectText">
                    <a href="https://rubygems.org/gems/inflation_db">RubyGems.org download page</a>
                </span><br /><span className="projectText">
                    <a href="https://github.com/dybushnell/inflation_db">Github repository</a>
                </span><br />
            </div> <br /> <br />
            <div className="portfolioContent">
                <div className="hed3">Numbers Are A Lie</div><br />
                <span className="projectDesc">
                    A Chrome extension which will change numbers on websites to random other numbers. Written with the primary intent to learn about the Chrome extension publishing process.
                </span><br />
                <br />
                <img src="http://www.dybushnell.com/images/numbers_lie.png"
                    alt="" />  <br /><br />
                <span className="projectText">
                    <a href="https://chrome.google.com/webstore/detail/numbers-are-a-lie/ehikaflimfbgammpeighhgpobjhpldkl">Chrome web store download page</a>
                </span><br /><span className="projectText">
                    <a href="https://github.com/dybushnell/extension">Github repository</a>
                </span><br />
            </div> <br /> <br />

        </div >

    )
}

export default Portfolio