import React from "react";
import { Link } from "react-router-dom";

const Sidebar = () => {
  return (
    <div className="sidebar">
      <img
        src="http://www.dybushnell.com/images/dyb_1_r_sm_sq.jpg"
        alt=""
        className="profile-pic"
      />
      <br />
      <Link to="/">
        <div className="hed1">David Bushnell</div></Link><br />
      <div>Full Stack Developer</div>
      <br />
      <br />
      <Link to="/">Home</Link>
      <br />
      <br />
      <Link to="/portfolio">Portfolio</Link>
      <br />
      <br />
      <Link to="/skills">Skills</Link>
      <br />
      <br />
      <Link to="/education">Education</Link>
      <br />
      <br />
      <Link to="/experience">Experience</Link>
      <br />
      <br />
      <Link to="/blog">Blog</Link>
      <br />
      <br />
      <Link to="/contact">Contact</Link>
      <br />
      <br />
    </div>
  );
};

export default Sidebar;
