import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="containerContent">
      <div className="hed2">Hello!</div><br /><br />
      <span>
        Hi, I'm Dave. I'm a full-stack developer living in Brooklyn, NY.
      </span>{" "}
      <br />
      <br />
      <span>
        I like B-movies from the 60's, 70's, and 80's, and I like punk and
        garage rock from the 60's, 70's, and 80's, but I don't like B-movies
        about punk and garage rock from the 60's, 70's, and 80's.
      </span>
      <br />
      <br />
      <span>
        Kim Kardashian's then-ex-husband made fun of my then-handlebar mustache
        in between takes of{" "}
        <a href="https://www.youtube.com/watch?v=BUu1TjStbqk">this video</a>. That was fun.
      </span>
      <br />
      <br />
      <span>
        I made{" "}
        <a href="https://www.youtube.com/watch?v=pAlJuJ8mLlY">
          a weirdo Christmas video
        </a>{" "}
        that convinced a girl to go out with me, and then we got married. That was (and still is!) cool.
      </span>
      <br />
      <br />
      <span>
        Hey, check out some of <Link to="/portfolio">my projects</Link> and{" "}
        <Link to="/contact">say hello</Link>!
      </span>
      <br />
      <br />
    </div>
  );
};

export default Home;
