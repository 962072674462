import React from "react";

const Blog = () => {
  return (
    <div className="containerContent">
      <div className="hed2">Blog posts ... written by me!</div><br />
      <br />
      {/* COPY AND PASTE ME
      <div className="portfolioContent">
        <div className="hed3">
          <a href="LINK TO STORY">
            TITLE OF STORY
          </a>
        </div><br />
        <span className="projectDesc">
          SUBHEAD
          <br />
          <br />
          Published on DATE
        </span>
      </div>{" "}
      <br />
      <br /> */}
      <div className="portfolioContent">
        <div className="hed3">
          <a href="https://medium.com/@dybushnell/anxiety-induced-dry-heaving-b3e8570ac583">
            Anxiety-induced dry heaving
          </a>
        </div><br />
        <span className="projectDesc">
          or: Why testing is important
          <br />
          <br />
          Published on Dec 15, 2019
        </span>
      </div>{" "}
      <br />
      <br />
      <div className="portfolioContent">
        <div className="hed3">
          <a href="https://medium.com/@dybushnell/hoisting-and-the-need-for-a-dramatis-personae-c5a18cb87881">
            Hoisting and the need for a dramatis personae
          </a>
        </div><br />
        <span className="projectDesc">Published on Dec 8, 2019</span>
      </div>
      <br />
      <br />
      <div className="portfolioContent">
        <div className="hed3">
          <a href="https://medium.com/@dybushnell/ben-sockets-d4447465a529">
            Ben-sockets
          </a>
        </div><br />
        <span className="projectDesc">
          Like websockets, only not as good!
          <br />
          <br />
          Published on Dec 1, 2019
        </span>
      </div>
      <br />
      <br />
      <div className="portfolioContent">
        <div className="hed3">
          <a href="https://medium.com/@dybushnell/sell-the-sizzle-because-the-steak-might-not-exist-2b0ff6bf8fdd">
            Sell the sizzle, because the steak might not exist
          </a>
        </div><br />
        <span className="projectDesc">
          Dating sucks.
          <br />
          <br />
          Published on Nov 23, 2019
        </span>
      </div>{" "}
      <br /> <br />
      <div className="portfolioContent">
        <div className="hed3">
          <a href="https://medium.com/@dybushnell/i-built-a-chrome-extension-to-tear-the-fabric-of-reality-and-you-can-too-f3b574e44257">
            I built a Chrome extension to tear the fabric of reality and you can
            too!
          </a>
        </div><br />
        <span className="projectDesc">
          But you don’t have to. You can build some other kind of Chrome
          extension. It’s kind of easy!
          <br />
          <br />
          Published on Sept 12, 2019
        </span>
      </div>{" "}
      <br /> <br />
      <div className="portfolioContent">
        <div className="hed3">
          <a href="https://medium.com/@dybushnell/regrets-brendan-eich-had-one-caa124d69471">
            Regrets? Brendan Eich had one
          </a>
        </div><br />
        <span className="projectDesc">
          Brendan Eich wrote Javascript in ten days. That is simultaneously my
          favorite fact and my least favorite fact.
          <br />
          <br />
          Published on Aug 21, 2019
        </span>
      </div>{" "}
      <br /> <br />
      <div className="portfolioContent">
        <div className="hed3">
          <a href="https://medium.com/@dybushnell/how-many-many-to-many-relationships-is-too-many-many-to-many-relationships-36db8396f03c">
            How many many-to-many relationships is too many many-to-many
            relationships?
          </a>
        </div><br />
        <span className="projectDesc">
          tldr : it’s 3<br />
          <br />
          Published on July 24, 2019
        </span>
      </div>{" "}
      <br /> <br />
      <div className="portfolioContent">
        <div className="hed3">
          <a href="https://medium.com/@dybushnell/how-to-pass-a-flatiron-school-code-challenge-9d9f2549c8f">
            How to pass a Flatiron School code challenge
          </a>
        </div><br />
        <span className="projectDesc">Published on July 24, 2019</span>
      </div>{" "}
      <br /> <br />
      <div className="portfolioContent">
        <div className="hed3">
          <a href="https://medium.com/@dybushnell/i-built-a-ruby-gem-to-calculate-inflation-and-you-can-too-5c9cff0826b6">
            I built a Ruby gem to calculate inflation and you can too!
          </a>
        </div><br />
        <span className="projectDesc">
          But you don’t have to. You can build some other kind of gem. It’s kind
          of easy!
          <br />
          <br />
          Published on July 17, 2019
        </span>
      </div>{" "}
      <br /> <br />
    </div>
  );
};

export default Blog;
