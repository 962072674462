import React from "react"

const Skills = () => {
    return (
        <div className="containerContent">
            <div className="hed2">Skills</div><br />
            <div>
                <ul>
                    <li>Javascript</li><br />
                    <li>React</li><br />
                    <li>Ruby on Rails</li><br />
                    <li>Git</li><br />
                    <li>Heroku</li><br />
                    <li>Adobe Photoshop</li><br />
                    <li>Adobe InDesign</li><br />
                </ul></div>
        </div>


    )
}

export default Skills