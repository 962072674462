import React from 'react';
import {
  BrowserRouter as Router,
  Route
} from 'react-router-dom';
import Sidebar from "./components/Sidebar"
import HomeContainer from "./containers/HomeContainer"
import PortfolioContainer from "./containers/PortfolioContainer"
import SkillsContainer from "./containers/SkillsContainer"
import EducationContainer from "./containers/EducationContainer"
import ExperienceContainer from "./containers/ExperienceContainer"
import BlogContainer from "./containers/BlogContainer"
import ContactContainer from "./containers/ContactContainer"


import './App.css';

function App() {
  return (
    <Router>
      <div className="app">
        <Sidebar />
        <Route exact path="/" render={() => (<HomeContainer />)} />
        <Route path='/portfolio' render={() => (<PortfolioContainer />)} />
        <Route path='/skills' render={() => (<SkillsContainer />)} />
        <Route path='/education' render={() => (<EducationContainer />)} />
        <Route path='/experience' render={() => (<ExperienceContainer />)} />
        <Route path='/blog' render={() => (<BlogContainer />)} />
        <Route path='/contact' render={() => (<ContactContainer />)} />


      </div>
    </Router>
  );
}

export default App;
