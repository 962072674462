import React from "react"

const Experience = () => {
    return (
        <div className="containerContent">
            <div className="hed2">Experience</div><br />
            <div>
                <ul><li>
                    <div className="hed3">Freelance Web Developer</div><br />
                    <span> Oct 2019 - present</span><br /><br />
                    <span> Making websites for fun and profit</span><br /><br />
                </li><br />
                    <li>
                        <div className="hed3">Layout Artist</div><br />
                        <span> Reproductions - New York, NY</span><br /><br />
                        <span> 2015 - 2019</span><br /><br />
                        <span> Designing headshots and promotional materials for actors and performing artists</span><br /><br />
                    </li><br />
                    <li>
                        <div className="hed3">Operations Specialist</div><br />
                        <span> Apple Fifth Ave - New York, NY</span><br /><br />
                        <span> 2011 - 2015</span><br /><br />
                        <span> Investigating inventory variances, general logistics and operations stuff, customer support</span><br /><br />
                    </li><br />
                    <li>
                        <div className="hed3">Layout Artist</div><br />
                        <span> The Ukrainian Weekly - Parsippany, NJ</span><br /><br />
                        <span> 2007-2011</span><br /><br />
                        <span> Designing a weekly 24-48 page newspaper</span><br /><br />
                    </li>
                </ul>
            </div>
        </div>


    )
}

export default Experience